/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCl1JOUXyj6z_kSUUQRxL5GoN3DcVoOuj4",
  "appId": "1:283461968640:web:2c1f6e303a992b9d44c9e5",
  "authDomain": "schooldog-i-pike-ga.firebaseapp.com",
  "measurementId": "G-K6FME9ET22",
  "messagingSenderId": "283461968640",
  "project": "schooldog-i-pike-ga",
  "projectId": "schooldog-i-pike-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-pike-ga.appspot.com"
}
